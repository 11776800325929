import axios from 'axios';
import { Storage, Auth } from 'aws-amplify';
import { REGION } from 'common/constants';

Storage.configure({
  bucket: process.env.REACT_APP_SFTP_FILE_BUCKET_NAME,
  region: REGION,
  customPrefix: {
    public: '',
    protected: '',
    private: ''
  }
});

const listS3Files = async (path) => {
  const currentAuthSession = await Auth.currentSession();
  const token = currentAuthSession.accessToken.jwtToken;

  const response = await axios.get(
    `${process.env.REACT_APP_API_REST_URL}/thread/sftp/paths${path ? `?navigation=${path}` : ''}`,
    {
      headers: {
        mode: 'no-cross',
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      }
    }
  );

  if (response.status !== 200) throw JSON.stringify(response.data);

  return response.data.data;
};

const getDocument = async (key) => {
  return Storage.get(key, { download: true });
};

const uploadFileToS3 = async (fileName, file) => {
  const currentAuthSession = await Auth.currentSession();
  const token = currentAuthSession.accessToken.jwtToken;

  const endpoint = `${process.env.REACT_APP_API_REST_URL}/thread/sftp/upload`;
  const body = { fileName, fileType: file.type };

  const options = {
    headers: {
      mode: 'no-cross',
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  };

  const response = await axios.post(endpoint, body, options);

  if (response.status !== 200) throw JSON.stringify(response.data);

  const { data: uploadSignedUrl } = response.data;

  return axios.put(uploadSignedUrl, file, {
    headers: {
      'Content-Type': file.type
    }
  });
};

export default { listS3Files, uploadFileToS3, getDocument };
