import useSftpHook from 'views/SFTP/SFTP.hook';
import { useCallback } from 'react';
import FolderButton from '../FolderButton';

function SearchViewer() {
  const { searchPaths, handlePathChange, handleSearch } = useSftpHook();

  const onChoose = useCallback((path) => {
    handlePathChange(path);
    handleSearch('');
  }, []);

  return (
    <div>
      {searchPaths.map((path, i) => (
        <div
          key={`search-path-${i}`}
          role="button"
          onClick={() => onChoose(path)}
          tabIndex={0}
          onKeyDown={() => onChoose(path)}
          className="flex items-start pr-6 py-4 hover:bg-secondaryLight hover:bg-opacity-20">
          <FolderButton
            option={path.split('/').pop()}
            customClass="py-2 bg-secondary bg-opacity-20 text-primary font-extrabold"
            open={false}
          />
        </div>
      ))}
    </div>
  );
}

export default SearchViewer;
