import { useCallback } from 'react';
import leftArrowIcon from 'assets/icons/icon-left-arrow.svg';
import useSftpHook from 'views/SFTP/SFTP.hook';
import FolderButton from '../FolderButton';
import DownloadButton from '../DownloadButton';
import IconButton from '../IconButton';

function Viewer() {
  const { handlePathChange, folders, files, isRoot, previousPath, path, handleDownloadAll } =
    useSftpHook();

  const handleNext = useCallback(
    (newPath) => {
      handlePathChange([...path, newPath]);
    },
    [path]
  );

  const handleGoBack = useCallback(() => {
    handlePathChange(path.slice(0, -1));
  }, [path]);

  const isOnLoanPackageFolder =
    path.some((p) => p === 'loanPackages') && path[1] && files.length > 0;

  const isOnLoanPackageDayFolder = path.some((p) => p === 'loanPackages') && path[2];

  const canShowDownloadAllButton = isOnLoanPackageFolder || isOnLoanPackageDayFolder;

  return (
    <div className={isRoot ? 'mt-8' : 'p-4'}>
      {!isRoot && (
        <div className="flex flex-col">
          <div id="navigation" className="flex flex-row justify-between align-middle">
            <IconButton
              handleClick={handleGoBack}
              option={previousPath === 'root' ? 'back' : previousPath}
              icon={leftArrowIcon}
            />
            {canShowDownloadAllButton && (
              <button
                id="download-all"
                type="button"
                onClick={() => handleDownloadAll()}
                className="flex flex-row align-middle gap-1 my-6 hover:font-extrabold hover:text-primary transition-transform hover:scale-105">
                Download Files
              </button>
            )}
          </div>
          <FolderButton
            option={path[path.length - 1]}
            customClass="py-4 bg-secondary bg-opacity-20 text-primary font-extrabold"
            open
          />
        </div>
      )}
      {folders?.map((item) => (
        <div
          key={`folder-${item}`}
          role="button"
          tabIndex={0}
          className="hover:bg-secondaryLight hover:bg-opacity-80"
          onClick={() => handleNext(item)}
          onKeyDown={() => handleNext(item)}>
          <FolderButton option={item} customClass="py-2 cursor-pointer" />
        </div>
      ))}
      {files?.map((item) => (
        <DownloadButton key={`file-${item}`} fileName={item} />
      ))}
    </div>
  );
}

export default Viewer;
