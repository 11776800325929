import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState, createContext, useContext } from 'react';
import JSZip from 'jszip';
import { FILES } from 'common/constants';
import { toast } from 'components/Toast/functions';
import FolderNavigationController from './controllers/FolderNavigationController';
import StorageController from './controllers/StorageController';
import { chunkPromiseHandler } from '../../common/helper';

const SftpContext = createContext({});

export function SftpProvider({ children, attributes }) {
  const basePath = useMemo(() => {
    const {
      attributes: { profile }
    } = attributes;

    return profile;
  }, [attributes]);

  const [progress, setProgress] = useState({
    loaded: 0,
    total: 0
  });
  const [file, setFile] = useState();
  const [showModal, setShowModal] = useState(false);
  const [path, setPath] = useState([]);
  const [pathTree, setPathTree] = useState({ folders: {}, files: [] });
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [timeoutId, setTimeoutId] = useState(setTimeout(() => {}, 0));
  const [loadingLabel, setLoadingLabel] = useState('');

  const handleSearch = (newSearch) => {
    if (newSearch !== search) {
      clearTimeout(timeoutId);

      const id = setTimeout(() => {
        setSearch(newSearch);
      }, 500);

      setTimeoutId(id);
    }
  };

  function downloadFile(data, fileName) {
    const url = URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
  }

  const handleDownload = async (fileName) => {
    const key = FolderNavigationController.getFileKey(fileName, path, basePath);
    const result = await StorageController.getDocument(key);

    downloadFile(result.Body, fileName);
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleUploadFile = async () => {
    const fileKey = FolderNavigationController.getFileKey(file ? file.name : '', path, basePath);
    setLoadingLabel('Uploading file...');
    setLoading(true);

    await StorageController.uploadFileToS3(fileKey, file);

    setLoading(false);
    setLoadingLabel('');
    window.location.reload();

    handleShowModal();
  };

  const handlePathChange = (content) => {
    const filesKeys = Object.keys(FILES);

    const newPath = (Array.isArray(content) ? content : content.split('/')).filter((c) => {
      const itemParts = c.split('.');
      const isFile = itemParts.length >= 2 && filesKeys.includes(itemParts[itemParts.length - 1]);

      return !isFile;
    });

    setPath(newPath);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const { files, folders } = useMemo(
    () => FolderNavigationController.getFolderContents(path, pathTree),
    [path, pathTree]
  );

  // Download all loan packages folders for day
  const downloadAllFolderFiles = async () => {
    // zip folder name
    const folderName = ['loanPackages', path[2]].join('_');
    // create new zip
    const zip = new JSZip();
    // chunk size
    const chunkSize = 150;
    // number of folders
    const numberOfFolders = folders.length;
    // downloaded folders
    let downloadedFolders = 0;

    setLoadingLabel(`Preparing ${folderName}.zip file...`);

    /**
     * For each folder inside path, get all files and add to zip
     */
    await chunkPromiseHandler(folders, chunkSize, async (folder) => {
      // create new folder inside zip
      const folderZip = zip.folder(folder);
      // get all files for folder
      const folderContents = FolderNavigationController.getFilesForFolder(folder, path, pathTree);

      /**
       * For each file inside folder, get file from S3 and add to zip
       */
      await chunkPromiseHandler(folderContents, folderContents.length, async (f) => {
        // get full file key
        const key = FolderNavigationController.getFolderFileKey(f, path, basePath, folder);

        // get file from S3 Buket
        const { Body: blob } = await StorageController.getDocument(key);

        // add file to folder inside zip
        folderZip.file(f, blob);
      });
      downloadedFolders += 1;
      setLoadingLabel(`Downloading ${downloadedFolders} of ${numberOfFolders} loan packages...`);
    });

    // generate zip
    const zipConntent = await zip.generateAsync({ type: 'blob' });

    setLoadingLabel(`zip file ready, downloading ${folderName}.zip...`);

    // download zip
    downloadFile(zipConntent, `${folderName}.zip`);
  };

  // Download loan package files
  const downloadFiles = async () => {
    const loanFolderId = path[3];
    setLoadingLabel(`Downloading loan package for ${loanFolderId}...`);

    const filesList = files.map((f) => ({
      name: f,
      key: FolderNavigationController.getFileKey(f, path, basePath)
    }));

    const filesMetaData = await Promise.all(
      filesList.map(async (f) => {
        const { Body: blob } = await StorageController.getDocument(f.key);
        return { name: f.name, blob };
      })
    );

    const zip = new JSZip();

    filesMetaData.forEach((f) => zip.file(f.name, f.blob));

    const folderName = ['loanPackages', loanFolderId].join('_');

    const zipConntent = await zip.generateAsync({ type: 'blob' });

    downloadFile(zipConntent, `${folderName}.zip`);
  };

  const handleDownloadAll = async () => {
    try {
      setLoading(true);

      // check if current path is a loan package folder
      if (files.length > 0) {
        console.time('download files execution time');
        await downloadFiles();
        console.timeEnd('download files execution time');
      }

      // check if current path is loan packages folders for day
      if (folders.length > 0) {
        console.time('download folder files execution time');
        await downloadAllFolderFiles();
        console.timeEnd('download folder files execution time');
      }

      setLoading(false);
      setLoadingLabel('');
    } catch (error) {
      toast('error', { message: 'Error when trying to download the files' }, 3000);
    } finally {
      setLoading(false);
      setLoadingLabel('');
    }
  };

  const isRoot = useMemo(() => {
    if (path.length === 0) return true;
    return false;
  }, [path]);

  const previousPath = useMemo(() => {
    if (path.length <= 2) return 'root';
    return path[path.length - 2];
  }, [path]);

  const searchPaths = useMemo(() => {
    if (!search) return null;

    const filtered = [];
    const pathsFiltered = {};
    const loanPackages = pathTree.folders.loanPackages.folders;

    // TODO: this should be replaced by a search endpoint from BE
    Object.keys(loanPackages).forEach((yearMonth) => {
      Object.keys(loanPackages[yearMonth].folders).forEach((yearMonthDay) => {
        Object.keys(loanPackages[yearMonth].folders[yearMonthDay].folders).forEach((loanFolder) => {
          if (loanFolder.toLowerCase().includes(search.toLowerCase())) {
            const searchPath = ['loanPackages', yearMonth, yearMonthDay, loanFolder].join('/');

            if (!pathsFiltered[searchPath]) {
              pathsFiltered[searchPath] = true;
              filtered.push(searchPath);
            }
          }
        });
      });
    });

    return filtered;
  }, [search, files, folders]);

  const getS3Files = useCallback(async () => {
    try {
      setLoading(true);
      setLoadingLabel('Loading folders and files...');

      // Return all files from S3
      const amplifyResults = await StorageController.listS3Files();

      setPathTree(amplifyResults);
    } catch (e) {
      toast('error', { message: 'Error while fetching files' }, 3000);
    } finally {
      setLoading(false);
      setLoadingLabel('');
    }
  }, [setPathTree]);

  useEffect(() => {
    getS3Files();
  }, []);

  const canShowSearchInput = useMemo(() => basePath === 'thread', [basePath]);

  const providerValue = useMemo(
    () => ({
      path,
      files,
      folders,
      previousPath,
      handlePathChange,
      handleDownload,
      handleFileChange,
      handleUploadFile,
      handleDownloadAll,
      file,
      showModal,
      handleShowModal,
      progress,
      setProgress,
      isRoot,
      search,
      handleSearch,
      searchPaths,
      loading,
      loadingLabel,
      setLoadingLabel,
      canShowSearchInput
    }),
    [
      path,
      files,
      folders,
      previousPath,
      handlePathChange,
      handleDownload,
      handleFileChange,
      handleUploadFile,
      handleDownloadAll,
      file,
      showModal,
      handleShowModal,
      progress,
      setProgress,
      isRoot,
      search,
      handleSearch,
      searchPaths,
      loading,
      loadingLabel,
      setLoadingLabel,
      canShowSearchInput
    ]
  );

  return <SftpContext.Provider value={providerValue}>{children}</SftpContext.Provider>;
}

SftpProvider.propTypes = {
  children: PropTypes.any.isRequired,
  attributes: PropTypes.object.isRequired
};

const useSftpHook = () => {
  const context = useContext(SftpContext);
  return context;
};

export default useSftpHook;
